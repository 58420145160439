

// IE Error

function getInternetExplorerVersion() {
    var rV = -1; // Return value assumes failure.
  
    if (navigator.appName === 'Microsoft Internet Explorer' || navigator.appName === 'Netscape') {
      var uA = navigator.userAgent;
      var rE = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
  
      if (rE.exec(uA) !== null) {
        rV = parseFloat(RegExp.$1);
      }
      /*check for IE 11*/
      else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        rV = 11;
      }
    }
    return rV;
  }
  
  
  
  // Preloader
  
  $(window).on('load', function() {
    "use strict";
    $('#preloader').fadeOut();
  
  });
  
  
  
  
  
  
  
  
  
  $(document).ready(function() {
  
  
  
    "use strict";
  
    var iEVersion = getInternetExplorerVersion();
    var alertText = "Kein IE";
    if (iEVersion !== -1) {
      alertText = "Internet Explorer " + iEVersion;
      //		alert(alertText);
      window.location.href = "http://oticon-beratung.de/internetexplorer.html";
  
    }
  
    console.log(alertText);
  
  });