(function(){
    document.getElementById('insertMenu').innerHTML = `
<div id="top-nav">
<div class="legaltext">
  <a href="datenschutz">Datenschutz</a>
  <a href="impressum">Impressum</a>
</div>
<img id="logo" src="assets/img/logo.svg" alt="">
<a href="/" id="home"><div class="home">
<div class="hover"></div>
<img src="assets/img/icn-home.svg" alt=""></div></a>
</div>

<div class="deviceSize">
<div class="deviceSizeContainer"><h1>Um diese Seite benutzen zu können, vergrößern Sie bitte Ihr Browserfenster oder benutzen Sie ein Gerät mit einem größeren Bildschirm.</h1>
<div class="screenAnim">
	<div class="screenSize"><p>min 900 x 660px</p>
	<div class="arrowH"></div>
	<div class="arrowV"></div>
	</div>
	<div class="arrowSreen"></div>
</div></div>
</div>`
})()