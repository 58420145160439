// Service Worker registrieren

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js').then(function () {
//     console.log("Service Worker Registered");
//   });
// }




var gaProperty = 'UA-105884898-1';

// Disable tracking if the opt-out cookie exists.
var disableStr = 'ga-disable-' + gaProperty;
if (document.cookie.indexOf(disableStr + '=true') > -1) {
  window[disableStr] = true;
}

// Opt-out function
function gaOptout() {
  document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
  window[disableStr] = true;
}







// Check if a new cache is available on page load.
window.addEventListener('load', function(e) {

  window.applicationCache.addEventListener('updateready', function(e) {
    if (window.applicationCache.status == window.applicationCache.UPDATEREADY) {
      // Browser downloaded a new app cache.
      // Swap it in and reload the page to get the new hotness.
      window.applicationCache.swapCache();
      if (confirm('Eine neuere Version ist verfügbar. Laden?')) {
        window.location.reload();
      }
    } else {
      // Manifest didn't changed. Nothing new to server.
    }
  }, false);

}, false);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
  }
  



// Preloader

$(window).on('load', function() {
  "use strict";
  $('#preloader').fadeOut();

});














// Ende



$(document).ready(function() {
  "use strict";

  $('.right').css({
    opacity: 0.1
  });

});


$('.side-menu').on('click', function() {
  "use strict";
  $('.side-menu').removeClass('sideActive');
  $(this).addClass('sideActive');
});


function wechslerKlangquali() {
  "use strict";

  $('#klangContent1').show();
  $('#klangContent2').hide();
  $('#klangContent3').hide();
  $('#sg').addClass('BTNactive');
  $('#cd').removeClass('BTNactive');
  $('#bb').removeClass('BTNactive');


}

function wechslerKlangquali2() {
  "use strict";

  $('#klangContent1').hide();
  $('#klangContent2').show();
  $('#klangContent3').hide();
  $('#sg').removeClass('BTNactive');
  $('#cd').addClass('BTNactive');
  $('#bb').removeClass('BTNactive');


}

function wechslerKlangquali3() {
  "use strict";

  $('#klangContent1').hide();
  $('#klangContent2').hide();
  $('#klangContent3').show();
  $('#sg').removeClass('BTNactive');
  $('#cd').removeClass('BTNactive');
  $('#bb').addClass('BTNactive');


}


function bildWechsel() {
  "use strict";

  $('#oldworld').attr('src', 'assets/img/newworld.gif');
  $('#RichtmikroNeu').addClass('BTNactive');
  $('#RichtmikroKlassisch').removeClass('BTNactive');

}

function bildWechsel2() {
  "use strict";

  $('#oldworld').attr('src', 'assets/img/oldworld.gif');
  $('#RichtmikroKlassisch').addClass('BTNactive');
  $('#RichtmikroNeu').removeClass('BTNactive');

}



function closeOverlay() {
  "use strict";
  $('.overlay').fadeOut('linear');
  $('.overlaySection-1').delay(500).animate({
    'left': '0'
  });
  $('.overlaySection-2').delay(500).animate({
    'left': '100%'
  });
  $('.right').css({
    opacity: 0.1
  });
  $('.left').css({
    opacity: 1
  });

}

function fadeOutVideo() {
  "use strict";
  $('video').trigger('pause');
  $('video')[0].currentTime = 0;
  $('.overlay').fadeOut('linear');
  $('.overlaySection-1').delay(500).animate({
    'left': '0'
  });
  $('.overlaySection-2').delay(500).animate({
    'left': '100%'
  });
  $('.right').css({
    opacity: 0.1
  });
  $('.left').css({
    opacity: 1
  });
  $('#RichtmikroKlassisch').addClass('BTNactive');
  $('#RichtmikroNeu').removeClass('BTNactive');
  $('#oldworld').attr('src', 'assets/img/oldworld.gif');
}


function fadeOutiframe() {
  "use strict";
  var iframe1 = $('.vimeo1')[0];
  var iframe2 = $('.vimeo2')[0];
  var iframe3 = $('.vimeo3')[0];
  var iframe4 = $('.vimeo4')[0];
  var iframe5 = $('.vimeo5')[0];
  var iframe6 = $('.vimeo6')[0];
  var player1 = $f(iframe1);
  var player2 = $f(iframe2);
  var player3 = $f(iframe3);
  var player4 = $f(iframe4);
  var player5 = $f(iframe5);
  var player6 = $f(iframe6);
  $('.overlay').hide();
  player1.api("pause");
  player2.api("pause");
  player3.api("pause");
  player4.api("pause");
  player5.api("pause");
  player6.api("pause");

}

function moveContent() {
  "use strict";
  $('.overlaySection-1').animate({
    'left': '-100%'
  }, 500);
  $('.overlaySection-2').animate({
    'left': '0'
  }, 500);
  $('video').trigger('pause');
  $('.right').css({
    opacity: 1
  });
  $('.left').css({
    opacity: 0.1
  });




}


function moveContentR() {
  "use strict";
  $('.overlaySection-1').animate({
    'left': '0'
  }, 500);
  $('.overlaySection-2').animate({
    'left': '100%'
  }, 500);
  $('video').trigger('pause');
  $('.right').css({
    opacity: 0.1
  });
  $('.left').css({
    opacity: 1
  });



}

function ccContent2() {
  "use strict";
  $('#cc-phone-1').animate({
    'left': '-100%'
  }, 500);
  $('#cc-phone-2').animate({
    'left': '0%'
  }, 500);
  $('.right').css({
    opacity: 1
  });
  $('.left').css({
    opacity: 0.1
  });
}

function ccContent1() {
  "use strict";
  $('#cc-phone-1').animate({
    'left': '0%'
  }, 500);
  $('#cc-phone-2').animate({
    'left': '100%'
  }, 500);
  $('.right').css({
    opacity: 0.1
  });
  $('.left').css({
    opacity: 1
  });
}

function moveContentPlay() {
  "use strict";
  $('.overlaySection-1').animate({
    'left': '-100%'
  }, 500);
  $('.overlaySection-2').animate({
    'left': '0'
  }, 500);
  $('.right').css({
    opacity: 1
  });
  $('.left').css({
    opacity: 0.1
  });


}


function moveContentRPlay() {
  "use strict";
  $('.overlaySection-1').animate({
    'left': '0'
  }, 500);
  $('.overlaySection-2').animate({
    'left': '100%'
  }, 500);
  $('.right').css({
    opacity: 0.1
  });
  $('.left').css({
    opacity: 1
  });


}


function ccRemote1() {
  "use strict";

  $('#ccRemote2').addClass('hidden');
  $('#ccRemote3').addClass('hidden');
  $('#ccRemote1').removeClass('hidden');
  $('button').removeClass('BTNactive');
  $('#btn-remote-1').addClass('BTNactive');

}

function ccRemote2() {
  "use strict";

  $('#ccRemote1').addClass('hidden');
  $('#ccRemote3').addClass('hidden');
  $('#ccRemote2').removeClass('hidden');
  $('button').removeClass('BTNactive');
  $('#btn-remote-2').addClass('BTNactive');

}

function ccRemote3() {
  "use strict";

  $('#ccRemote1').addClass('hidden');
  $('#ccRemote2').addClass('hidden');
  $('#ccRemote3').removeClass('hidden');
  $('button').removeClass('BTNactive');
  $('#btn-remote-3').addClass('BTNactive');

}


// RangeSlider Sozial

$(document).on('input change', '#rangeSozial', function(){
  var rangeValue = this.value;
  console.log(rangeValue);
  $('#sozial03').css('width', rangeValue*4.28);
  $('#sozial02').css('width', rangeValue*5.8);
  $('#sozial01').css('width', rangeValue*5.8);
})